import React, { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useTranslation } from "react-i18next";
import SEO from "root/components/SEO";
import Layout from "root/components/Layout";
import Navbar from "root/components/Navbar";
import Apoios from "root/components/Apoios";
import Footer from "root/components/Footer";

import CareersHero from "root/sections/CareersHero";
import CareersPerks from "root/sections/CareersPerks";
import CareersJoinUs from "root/sections/CareersJoinUs";
import CareersKnowMore from "root/sections/CareersKnowMore";

const CareersPage = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage("pt-PT");
  }, []);

  const color = "blue";

  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "careersHero.png" }) {
        childImageSharp {
          fluid(maxWidth: 1016, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      perks: file(relativePath: { eq: "careersPerks.png" }) {
        childImageSharp {
          fluid(maxWidth: 602, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      joinUs: file(relativePath: { eq: "careersJoinUs.png" }) {
        childImageSharp {
          fluid(maxWidth: 455, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      knowMore: file(relativePath: { eq: "careersKnowMore.png" }) {
        childImageSharp {
          fluid(maxWidth: 584, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      knowMoreGrid: file(relativePath: { eq: "knowMoreGrid.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div>
      <SEO title={t("pages.careers.label")} />
      <Layout>
        <Navbar color={color} currentPage="careers" blueLogin="true" />
        <CareersHero
          translations={t("careers.hero")}
          image={data.hero}
          color={color}
        />
        <CareersPerks
          translations={t("careers.perks")}
          image={data.perks}
          color={color}
        />
        <CareersJoinUs translations={t("careers.joinUs")} image={data.joinUs} />
        <CareersKnowMore
          translations={t("solutionCities.knowMore")}
          image={data.knowMore}
          knowMoreGrid={data.knowMoreGrid}
        />
        <Footer />
        <Apoios />
      </Layout>
    </div>
  );
};

export default CareersPage;
